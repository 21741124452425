*,
html,
body,
p {
    margin: 0;
    padding: 0;
}
a,
a:active,
a:focus,
a:hover {
    color: inherit;
}
.blue {
    color: blue;
}
html,
body {
    height: 100%;
}
#root {
    height: 100%;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex: 0 0 auto;
}
.row-hover {
    cursor: pointer;
}
#root .row-hover.row-selected,
#root .row-hover.row-selected:hover {
    background: #3f8aca;
    color: #fff;
}
.people-menu-item {
    border: 1px solid #eaeaea;
    width: 100px;
    height: 100px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 1px;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 23px 2px;
    line-height: 17px;
}
.people-menu-item i {
    margin-bottom: 4px;
}

.active .people-menu-item {
    border-color: #40a9ff;
    border-width: 3px;
    padding: 21px 0px;
}
#root .ag-cell:not(.ag-cell-focus) {
    border: 1px solid #f5f5f5;
}
#root .compact-table th,
#root .compact-table td {
    padding: 1px;
    font-size: 12px;
}
#root .compact-table th {
    text-align: center;
}
#root .ag-header-cell-label,
#root .ag-header-group-cell-label {
    justify-content: center;
}
/* reduce ag-grid default header line-height and padding */
#root .ag-theme-balham .ag-header-cell {
    line-height: normal;
    padding: 8px;
}

/* make header separator height proportional to header height */
#root .ag-theme-balham .ag-header-cell:after,
#root .ag-theme-balham .ag-header-group-cell:after {
    height: 50%;
}

/* set icons height to their real absolute value to ensure proper vertical alignment */
#root .ag-theme-balham .ag-header-cell-menu-button .ag-icon-menu,
#root .ag-theme-balham .ag-header-cell-label .ag-header-icon {
    height: 16px;
}

/* make header text wrap, without breaking words and without ellipsis */
#root .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    height: auto;
    overflow: visible;
    overflow-wrap: normal;
    text-overflow: clip;
    white-space: normal;
}
.right-up {
    top: 0;
    background: url('./images/right_up.png');
    height: 299px;
}
.right-down {
    bottom: 0;
    background: url('./images/right_down.png');
    height: 281px;
}
.right-down, .right-up {
    position: absolute;
    right: 0;
    width: 120px;
}
.left-down, .left-up {
    position: absolute;
    left: 0;
    width: 120px;
}
.left-up {
    top: 0;
    background: url('./images/left_up.png');
    height: 221px;
}
.left-down {
    bottom: 0;
    background: url('./images/left_down.png');
    height: 211px;
}


.login-logo-svfu {
    background: url('./images/logo_svfu.png');
    width: 100px;
    height: 86px;
}

 @media  (max-width:1000px) {
    .left-up,.left-down,.right-down,.right-up {
        display: none;
    }
}

@media  (max-height:800px) {
    .left-up,.left-down,.right-down,.right-up,.login-logo-svfu {
        display: none;
    }
}
@media  (max-height:600px) {
    .header {
        display: none;
    }
}



table {
    font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
    font-size: 12px;
    border: 6px solid #24943A;
    background-color: #D4EED1;
    width: 100%;
    text-align: center;
  }
  table td, table.greenTable th {
    border: 1px solid #24943A;
    padding: 3px 2px;
  }
  table tbody td {
    font-size: 13px;
  }
  table thead {
    background: #24943A;
    color: white;
    background: -moz-linear-gradient(top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: -webkit-linear-gradient(top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: linear-gradient(to bottom, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    border-bottom: 0px solid #444444;
  }
  table thead th {
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-align: left;
    border-left: 2px solid #24943A;
  }
  
  
  table tfoot {
    font-size: 13px;
    font-weight: bold;
    color: #F0F0F0;
    background: #24943A;
    background: -moz-linear-gradient(top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: -webkit-linear-gradient(top, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    background: linear-gradient(to bottom, #5baf6b 0%, #3a9e4d 66%, #24943A 100%);
    border-top: 1px solid #24943A;
  }
  table tfoot td {
    font-size: 13px;
  }
  table tfoot .links {
    text-align: right;
  }
  table tfoot .links a{
    display: inline-block;
    background: #FFFFFF;
    color: #24943A;
    padding: 2px 8px;
    border-radius: 5px;
  }

    .resp-container {
        position: relative;
        overflow: hidden;
        padding-top: 100%;
    }
    .resp-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }